import React, { Component } from 'react';
import './MarksMUI.css'
import Moment from 'moment';
import {Spiner} from '../../Spiner/Spiner';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SubjectIcon} from './SubjectIcon/SubjectIcon';
import {MarkIcon} from './MarkIcon/MarkIcon';
import {MarkIconDesc} from './MarkIcon/MarkIconDesc';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CurrMarksChart from './CurrMarksChart';
import Grid from '@material-ui/core/Grid';
import MarkTypeSelector from './MarkTypeSelector';
import PeriodChart from './PeriodChart';

export class MarksMUI extends React.PureComponent {
	constructor(props) {
        super(props);  
        this.state = {selKey : -1, id_tipa : -1}  

        this.handleOnRowSelected = this.handleOnRowSelected.bind(this);
    }

	componentDidMount() {		
	}

	handleOnRowSelected(e, mark, index){	
		this.setState({selKey : index})
		this.props.onRowSelected(mark);
	}

	getMjesec = (datum) => {
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (datum) => {
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getAvg(sum, cnt){
		if (cnt == 0)
			return '';

		return Math.round((sum / cnt) * 100) / 100;
	}
	getTekucaStatistika = (marks) =>{
		let data = [
	     	{ocjena: '1', "ukupan broj": 0},
	     	{ocjena: '2', "ukupan broj": 0},
	     	{ocjena: '3', "ukupan broj": 0},
	     	{ocjena: '4', "ukupan broj": 0},
	     	{ocjena: '5', "ukupan broj": 0}
		];
		let sum = 0; 
		let cnt = 0; 
		marks.map((mark, index) =>	{
			if (mark.ocjena == '1' || mark.ocjena == '2' || mark.ocjena == '3' || mark.ocjena == '4' || mark.ocjena == '5'){
				let ocjena = parseInt(mark.ocjena);
				data[ocjena - 1]["ukupan broj"]++;
				cnt++;
				sum += ocjena;
			}
		});
		return {data : data, avg : this.getAvg(sum, cnt)};
	}

	getUkupnaStatistika = (marks) =>{
		let data = [
	     	{period: 'I', suma: 0, broj : 0, prosjek : ''},
	     	{period: 'II', suma : 0, broj : 0, prosjek : ''},
	     	/*{period: 'III', suma : 0, broj : 0, prosjek : ''},
	     	{period: 'IV', suma : 0, broj : 0, prosjek : ''},*/
		];

		marks.map((curr_mark, index) =>	{
			curr_mark.ocjene.map((mark, index) =>	{
				if (mark.ocjena == '1' || mark.ocjena == '2' || mark.ocjena == '3' || mark.ocjena == '4' || mark.ocjena == '5'){
					if (mark.id_tipa_ocjene == '3' || mark.id_tipa_ocjene == '4' || mark.id_tipa_ocjene == '5' || mark.id_tipa_ocjene == '6'){
						let ocjena = parseInt(mark.ocjena);
						let tip = parseInt(mark.id_tipa_ocjene);
						data[ tip - 3 ]["broj"] += 1;
						data[ tip - 3 ]["suma"] += ocjena;
					}
				}
			})
		});
		data[0].prosjek = this.getAvg(data[0].suma, data[0].broj);
		data[1].prosjek = this.getAvg(data[1].suma, data[1].broj);
		/*data[2].prosjek = this.getAvg(data[2].suma, data[2].broj);
		data[3].prosjek = this.getAvg(data[3].suma, data[3].broj);*/
		return data;
	}

	handleChangeType = (type_id) => {
		if(this.props.onChangeType)
			this.props.onChangeType(type_id);
	}
	/*<div className="bottom-flex-container">
													{mark.naziv_cilja &&
														<MarkIconDesc mark={mark.ocjena}/>
													}
													{!mark.naziv_cilja && <MarkIcon mark={mark.ocjena}/>}
													{!mark.naziv_cilja && <div>{mark.nazivtipa}</div>}																					
												</div>
												{mark.naziv_cilja && <div>{mark.nazivtipa}</div>}*/
    render() {
    	const marks = this.props.marks;
  		//console.log(marks);
  		return (
  			
        	<div>
        		<MarkTypeSelector mark_types={this.props.mark_types} type_id={this.props.type_id} onChange={this.handleChangeType}/>
				<Grid container spacing={3}>
					<Grid item md={8} className="marks-grid-container">
			            <div className="marks-container">
				            <List style={{padding:'0px', margin : '0px'}}>			
								{marks.map((mark, index) =>					
									<ListItem button key={mark.naziv} style={{padding:'0px', margin : '0px'}}>
										<div className="main-flex-container" onClick={(e) => this.handleOnRowSelected(e, mark, index)}>
											<SubjectIcon naziv={mark.naziv} id={mark.idGrupe}/>
											<div className="right-flex-container">
												<div className="upper-flex-container">
													<div className="main-text">
														{mark.naziv}
													</div>										
												</div>
												<div className="bottom-flex-container">
													{!mark.naziv_cilja && <MarkIcon mark={mark.ocjena}/>}
													{mark.naziv_cilja && <MarkIconDesc mark={mark.ocjena}/>}
													<div className="centered-container">{mark.nazivtipa}</div>																				
												</div>												
												{mark.naziv_cilja &&													
													<div className="bottom-flex-container top-border">
														{mark.naziv_cilja}
													</div>
												}
											</div>
											<div className="date-container">
												<div>{this.getDan(mark.datum)}</div>
												<div>{this.getMjesec(mark.datum)}</div>
											</div>
										</div>
									</ListItem>
								)}
							</List>
						</div>
					</Grid>
					<Grid item md={4} className="chart-grid-container">
						
						<div className="chart-container">
				          	<CurrMarksChart data={this.getTekucaStatistika(this.props.marks)} is_complete={false}/>
				        </div>
						{true &&	
						<div className="chart-container chart-container-mt3">
				          	<PeriodChart data={this.getUkupnaStatistika(this.props.marks)}/>
				        </div>}
			    	</Grid>
			     </Grid>
			</div>
        );
    }
}
