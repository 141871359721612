import React, { Component } from 'react';


export class PrivacyPolicy extends React.Component {
	constructor(props) {
        super(props);
    }

    render() {
    	
        return (
            <div>
                 <strong>Privacy Policy</strong>
                 <p>
                  Ministarstvo prosvjete Crne Gore built the eDnevnik ME app as
                  a Free app. This SERVICE is provided by
                  Ministarstvo prosvjete Crne Gore at no cost and is intended for use as
                  is.
                </p> 
                <p>
                  This page is used to inform visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p>
                <p>
                  If you choose to use our Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that we collect is
                  used for providing and improving the Service. We will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p>
                <p><strong>Information Collection and Use</strong></p>
                <p>
                  We do not collect any personal information about you when you use the App. 
				  We do not use any third-party services that may collect information about you. 
				  The App is designed to function independently without any personal data collection or advertising.
                </p>

                <p><strong>How We Use Information</strong></p> <p>
                  Since we do not collect any personal information, we do not use it for any purpose.
                </p> 


                <p><strong>Information Sharing and Disclosure</strong></p> <p>
                  We do not share, sell, or disclose any information collected through the App since no information is collected.
                </p>

                

                <p><strong>Children's Privacy</strong></p> 
                <p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do the necessary actions.
                  </p>

                  <p><strong>Changes to This Privacy Policy</strong></p> <p>
                  We may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> 

                <p>This policy is effective as of 2024-01-01</p> 
                <p><strong>Contact Us</strong></p> 
                <p>
                  If you have any questions or suggestions about our
                  Privacy Policy, do not hesitate to contact us at info@ict.edu.me.
                  </p>

                
            </div>
        );
    }
}
