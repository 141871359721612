import React, { Component } from 'react';
import { connect } from 'react-redux';
//import logo from './logo.svg';

import './App.css';
import {Login} from './Login/Login';
import {Main} from './Main/Main';
import {Admin} from './Admin/Admin';
import {PrivacyPolicy} from './PrivacyPolicy';

import { BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import {history} from './_helpers/history';
import httpService from './network-service';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';

class App extends React.Component {
	
	state = { response: '', post: '', responseToPost: ''};
	constructor(props) {
        super(props);

        const { dispatch } = this.props;
    }
	
    componentDidMount() {		    
        /*if (!this.props.loggedIn){
          this.props.history.push("/login");
          return;
        }*/
        //console.log('App props', this.props);
        httpService.setHistory(this.props.history);
        this.props.history.push("/main");
    }
	
	render() {
		return (
            <div className="App">        
                <div>
                    <Route path="/main" component={Main} />
                    <Route path="/login" component={Login} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                </div>
            </div>            
            
    );
  }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    return {
        loggedIn
    };
}

/*App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};*/

const connectedApp = withRouter(connect(mapStateToProps)(App));
export {connectedApp as App}; 
